<template>
  <div class="patientInfo">
    <div class="patientInfo_main">
      <el-avatar shape="circle" :size="50" fit="fit" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
      <div class="patientInfo_nameabout">
        <div class="patientInfo_name">{{patient.userName}}</div>
        <div class="patientInfo_age">{{patient.age ? patient.age+'岁' : ''}}</div>
      </div>
    </div>
    <div class="patientInfo_subinfo">
      <div>
        <span>电话: </span>
        <span>{{patient.phoneNo}}</span>
      </div>
      <div class="patientInfo_infoitmes">
        <span>性别: </span>
        <span>{{patient.gender}}</span>
      </div>
      <div class="patientInfo_infoitmes">
        <span>出生年月: </span>
        <span v-if="patient.birth">{{patient.birth | dateFormat('YYYY-MM-DD')}}</span>
        <span v-else>-- </span>
      </div>
    </div>
    <div class="patientInfo_subinfo">
      <div >
        <span>紧急联系人姓名: </span>
        <span>{{patient.backupCaller ? patient.backupCaller : '--'}}</span>
      </div>
      <div class="patientInfo_infoitmes">
        <span>紧急联系人电话: </span>
        <span>{{patient.backupPhoneNo ? patient.backupPhoneNo : '--'}}</span>
      </div>
      <div class="patientInfo_infoitmes">
        <span>紧急联系人关系: </span>
        <span>{{patient.backupRelation ? patient.backupRelation :'--'}}</span>
      </div>
    </div>
    <div class="patientInfo_subinfo">
      <div >
        <span>加入时间: </span>
        <span>{{patient.createTime | dateFormat('YYYY-MM-DD')}}</span>
      </div>
      <div class="patientInfo_infoitmes">
        <span>家庭地址: </span>
        <span v-if="patient.province">{{(patient.province? patient.province : '') + (patient.city ? '/'+patient.city : '') + (patient.country ? '/'+patient.country : '')}}</span>
        <span v-else>--</span>
      </div>
      <div class="patientInfo_infoitmes">
        <span>详细地址: </span>
        <span>{{patient.detailAddress ? patient.detailAddress : '--'}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "patientinfocard",
  props:{
    patient:{
      type:Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>
.patientInfo{
  background-color: #0099CC;
  color: #FFFFFF;
  border-radius: 10px;
  height: 150px;
  box-shadow:-2px -2px 2px 2px #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}
.patientInfo_main{
  display: flex;
  align-items: center;
  margin-left: 50px;
  border-right: 1px solid #FFFFFF;
  width: 150px;
  margin-right: 50px;
}
.patientInfo_nameabout{
  margin-left: 20px;
}
.patientInfo_age{
  margin-top: 10px;
}
.patientInfo_subinfo{
  margin-right: 150px;
  flex-shrink:0
}
.patientInfo_infoitmes{
  margin-top: 20px;
}
</style>
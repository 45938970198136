<template>
  <div class="main page-container">
    <Breadcrumb></Breadcrumb>
    <patientinfocard :patient.sync="patientInfo"></patientinfocard>
    <div class="formregion_warpper">
      <div class="formgion">
        <div class="formgion_item">
          <div class="title">问卷内容:</div>
          <SurveyBuilder
            :data="surveyJson"
            :calculator="surveyCalculator"
            :onlyOneTimesData="onlyOneTimesData"
          />
          <!-- <el-button @click="surveyCalculator">test</el-button> -->
          <div class="btn">
            <!-- <el-button type="primary" @click="onSubmit">提交</el-button> -->
            <el-button @click="cancelFillQues">取消</el-button>
          </div>
        </div>
        <div class="formgion_item">
          <div class="title">工单记录:</div>
          <el-table :data="tableData" border size="mini" style="width: 100%">
            <el-table-column prop="createTime" label="创建时间" width="180">
              <template slot-scope="socpe">{{
                socpe.row.createTime | dateFormat("YYYY-MM-DD HH:mm:ss")
              }}</template>
            </el-table-column>
            <el-table-column prop="problemDescription" label="问题描述">
            </el-table-column>
            <el-table-column prop="assistantConclusion" label="医助处理结果">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="formgion-left">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="呼叫" name="first">
            <div class="relationsPhoneList">
              <div
                class="title"
                v-for="(item, index) in relationsList"
                :key="index"
              >
                <div>{{ item.name }} :{{ item.phone }}</div>
                <span @click="telNum(item.phone)" style="cursor: pointer"
                  >呼叫</span
                >
              </div>
            </div>
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item
                label="是否应答"
                prop="isAnswer"
                label-width="100px"
              >
                <el-radio v-model="teachFrom.isAnswer" :label="1">是</el-radio>
                <el-radio v-model="teachFrom.isAnswer" :label="0">否</el-radio>
              </el-form-item>
              <el-form-item
                label="下次提醒时间"
                prop="nextTime"
                label-width="100px"
                v-show="teachFrom.isAnswer === 0"
              >
                <div class="teachTime-box">
                  <el-date-picker
                    style="margin-bottom: 12px"
                    v-model="teachFrom.nextTime"
                    type="date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                  <el-time-picker
                    v-model="teachFrom.handM"
                    value-format="HH:mm:ss"
                    placeholder="选择任意时间点"
                  >
                  </el-time-picker>
                </div>
              </el-form-item>
            </el-form>
            <div class="btngroups" v-if="teachFrom.isAnswer === 0">
              <el-button @click="cancelbtn">取 消</el-button>
              <el-button type="primary" @click="confirmEvent(0)"
                >确 定</el-button
              >
            </div>
          </el-tab-pane>
          <el-tab-pane label="添加工单" name="second">
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item
                label="医生工作室"
                prop="office"
                label-width="100px"
              >
                <el-input v-model="patientInfo.groupName" disabled></el-input>
              </el-form-item>
              <el-form-item
                label="患者姓名"
                prop="userName"
                label-width="100px"
              >
                <el-input v-model="patientInfo.userName" disabled></el-input>
              </el-form-item>
              <el-form-item label="事件描述" prop="des" label-width="100px">
                <el-input
                  v-model="teachFrom.des"
                  type="textarea"
                  :maxlength="100"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btngroups">
              <el-button @click="cancelbtn">取 消</el-button>
              <el-button type="primary" @click="confirmEvent(1)"
                >确 定</el-button
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import patientinfocard from "@/views/taskcenter/components/patientinfocard";
import { Debounce } from "../../tools/utils";
// import { timeMode } from "@/tools/utils.js";
import SurveyBuilder from "@/components/Survey/SurveyBuilder";
import { survey, calculator, onlyOneTimesData } from "./survey.config";
export default {
  name: "fillquestionnaire",
  components: { patientinfocard, SurveyBuilder },
  data() {
    return {
      surveyJson: survey,
      onlyOneTimesData,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      patientInfo: {},
      questionsList: [],
      activeName: "first",
      relationsList: [],
      teachFrom: {
        teachId: "",
        userId: "",
        isAnswer: 1,
        des: "",
        teachTime: "",
        name: "",
        office: "",
        nextTime: "",
        handM: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      userInfo: {},
      tableData: [],
      followUpId: "",
      userId: "",
      base64: "",
      isShowOther: "",
      isFlag: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  created() {
    this.myDeBonce = new Debounce(500);

    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.followUpId = this.$route.query.followUpId;
    this.userId = this.$route.query.userId;
    this.getQuestionsList(this.followUpId);
    this.getPatienInfo(this.userId);
    this.getRelationsList(this.userId);
    this.getOrderTableList(this.userId, this.followUpId);
    this.test();
  },
  methods: {
    test() {
      console.log("//");
      let obj = {
        difficultyFallingAsleep: "0",
        earlyAwakening: "0",
        sackTime: "23:00",
        sleepLongth: 3,
        wakeupTime: "07:00",
      };
      console.log(obj, new Date("08:00"));
      let sackTime = new Date(`2023-09-09 ${obj.sackTime}`).getTime();
      let wakeupTime = new Date(`2023-09-10 ${obj.wakeupTime}`).getTime();
      let residue1 = (sackTime - wakeupTime) % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
      let hours = Math.floor(residue1 / (3600 * 1000));
      console.log(sackTime);
      console.log(wakeupTime);
      console.log(hours);
    },
    surveyCalculator(data) {
      calculator(data);
    },
    //获取问卷列表数据
    async getQuestionsList(followId) {
      const params = {
        followUpId: followId + "",
      };
      const res = await this.$http.getFollowUpQuestionsList(params);

      this.questionsList = res.result.data;
      this.questionsList.forEach((item) => {
        item.chooseContent = item.chooseContent
          ? JSON.parse(item.chooseContent)
          : [];

        item.chooseContent.sort(this.compare("answerId"));

        item.linkAnswerInfo = item.linkAnswerInfo
          ? JSON.parse(item.linkAnswerInfo)
          : [];
      });
    },
    // 排序
    compare(property) {
      return function (a, b) {
        return a[property] - b[property];
      };
    },
    //单选框选择事件
    dealRadioValue(answer, questions, index) {
      questions.requestValue = {
        questionId: answer.questionId,
        answerId: questions.userAnswerId,
      };
      let _params = {
        questionId: questions.questionId,
      };
      // if (!questions.trueAnswerId) return;
      let res;
      // let trueAnswerIdArr = [];
      // questions.trueAnswerId.forEach((item) => {
      //   trueAnswerIdArrush(item.answerId);
      // });
      // const isGetSubsQues = trueAnswerIdArr.includes(
      //   questions.userAnswerId + ""
      // );

      // 处理子问题
      if (questions.trueAnswer) {
        let trueAnswer = JSON.parse(questions.trueAnswer);
        trueAnswer.forEach(async (item) => {
          // 获取子问题
          if (item.answerId == answer.answerId) {
            res = await this.$http.getsubsQuestions(_params);
            res.result.data.forEach((item) => {
              item.questionId = item.sQuestionId;
              item.chooseContent = item.content ? JSON.parse(item.content) : [];
              item.chooseContent.sort(this.compare("answerId"));
              item.checkBox = [];

              item.linkAnswerInfo = item.linkAnswerInfo
                ? JSON.parse(item.linkAnswerInfo)
                : [];

              var _index = this.questionsList.findIndex(
                (i) => i.questionId === item.questionId
              );
              if (_index !== -1) {
                // this.questionsList.splice(_index,1)
                return;
              } else {
                this.questionsList.splice(index + 1, 0, item);
              }
            });
          } else {
            // 处理点击父问题后获取到了子问题然后又点击其他选项清除已加载的子问题
            console.log(99999);
            this.questionsList = this.questionsList.filter((ite) => {
              return ite.pQuestionId != questions.questionId;
            });
          }
        });
      }

      // 处理选项下的子选项
      if (
        questions.linkAnswerInfo.length &&
        answer.answerId == questions.linkAnswerInfo[0].answerId
      ) {
        this.isFlag = true;
      } else {
        this.isFlag = false;
      }

      // else if (isGetSubsQues == false) {
      //   res = await this.$http.getsubsQuestions(_params);
      //   res.result.data.forEach((item) => {
      //     item.questionId = item.sQuestionId;
      //     var _index = this.questionsList.findIndex(
      //       (i) => i.questionId === item.questionId
      //     );
      //     if (_index !== -1) {
      //       this.questionsList.splice(_index, 1);
      //     } else return;
      //   });
      // }
    },

    handleCheckedCitiesChange(value) {
      console.log(value, "value----");
      if (value.linkAnswerInfo) {
        let linkAnswerInfo = JSON.parse(value.linkAnswerInfo);

        if (value.checkBox.includes(linkAnswerInfo[0].answerId)) {
          this.isShowOther = true;
        } else {
          this.isShowOther = false;
        }
      }
    },

    //获取患者信息
    async getPatienInfo(id) {
      const params = {
        // value:{
        userId: id,
        // }
      };
      const res = await this.$http.getPatientInfoMation(params);
      res.result.data.age = this.$moment().diff(res.result.data.birth, "years");
      if (res.result.data.gender == 1) {
        res.result.data.gender = "男";
      } else if (res.result.data.gender == 2) {
        res.result.data.gender = "女";
      } else {
        res.result.data.gender = "";
      }
      this.patientInfo = res.result.data;
    },
    cancelFillQues() {
      this.$router.push("taskcenter");
    },
    handleClick() {
      console.log(this.activeName);
    },
    //获取当前患者通讯录
    getRelationsList(val) {
      let params = {
        userId: val,
      };
      this.$http.getRelationsPhone(params).then((res) => {
        this.relationsList = res.result.data;
      });
    },
    cancelbtn() {
      this.teachFrom.handM = "";
      this.teachFrom.nextTime = "";
      this.teachFrom.des = "";
    },
    confirmEvent(type) {
      if (type === 1) {
        this.addOrderConfirm();
      } else {
        this.updateFollowUpReq();
      }
    },
    //下次提醒
    async updateFollowUpReq() {
      await this.myDeBonce.debounceEnd();

      if (this.teachFrom.nextTime === "" || this.teachFrom.handM === "")
        return this.$message.error("请先填写下次提醒时间");
      let params = {
        followUpId: this.followUpId,
        isAnswer: 0,
        nextTime: this.teachFrom.nextTime + " " + this.teachFrom.handM,
      };
      const res = await this.$http.updateRemindFollowUp(params);
      if (res.result.msg === "success") {
        this.$message.success("处理成功!");
      } else {
        this.$message.error("处理失败!");
      }
    },
    //确认添加工单
    async addOrderConfirm() {
      await this.myDeBonce.debounceEnd();

      //隐藏对话框
      if (this.teachFrom.des === "")
        return this.$message.error("请先填写事件描述");
      let params = {
        followUpId: this.followUpId,
        customerServerId: this.userInfo.customerServerId,
        groupId: this.patientInfo.groupId,
        userId: this.userId,
        problemDescription: this.teachFrom.des,
        assistantId: this.patientInfo.assistantId,
        workOrderType: 0,
      };
      //添加工单请求
      const res = await this.$http.addWorkOrder(params);
      if (res.result.msg == "success") {
        this.$message.success("添加成功!");
        this.teachFrom.des = "";
        this.getOrderTableList(this.userId);
      } else {
        this.$message.error("添加失败!");
      }
    },
    //获取工单信息
    async getOrderTableList(userId, followUpId) {
      let params = {
        customerServerId: this.userInfo.customerServerId,
        workOrderType: 0,
        userId,
        followUpId,
      };
      //获取工单信息
      const res = await this.$http.getWorkManageInfo(params);
      this.tableData = res.result.data;
    },
    telNum(phone) {
      ClinkAgent.previewOutcall({ tel: phone });
    },
  },
};
</script>

<style scoped lang="less">
.main {
  position: relative;
}
.formgion {
  width: 100%;
  // max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  .btn {
    background-color: #ffffff;
    text-align: center;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
  }
}
.formgion::-webkit-scrollbar {
  display: none;
}
.formgion-left {
  width: 35%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-left: 16px;
  // max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}
.formgion-left::-webkit-scrollbar {
  display: none;
}
.formregion_warpper {
  flex: 1;
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.formgion_item {
  margin-bottom: 20px;
  .title {
    color: #0099cc;
    margin-bottom: 20px;
    font-size: 24px;
  }
}
.subsQuestion {
  margin-left: 30px;
}
.relationsPhoneList {
  margin-bottom: 12px;
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    span {
      color: #409eff;
    }
    div:first-child {
      padding-left: 46px;
    }
  }
}
.teachTime-box {
  display: flex;
  flex-direction: column;
}
/deep/.el-form {
  margin: 0;
}
.btngroups {
  text-align: center;
}
</style>
